<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
             <input type="text" :placeholder="$t('searchArea.id')" v-model="reqData.adminId" />
             <button class="btn-search ml-5" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
         <!-- <div class="box-ui-check searchbar-content mr-5">
           <div class="title">
             <span>권한</span> :
           </div>
           <select>
             <option>선택옵션</option>
           </select>
         </div> -->
         <!--div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('searchArea.loginYN') }}</span> :</div>
           <select v-model="reqData.loginYn">
             <option value="">{{ $t('searchArea.total') }}</option>
             <option value="Y">{{ $t('common.possible') }}</option>
             <option value="N">{{ $t('common.impossible') }}</option>
           </select>
         </div>
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('searchArea.delM') }}</span> :</div>
           <div class="toggle-wrap">
             <label class="toggle-text default on"></label>
             <input type="checkbox" class="set-switch" v-model="reqData.delYn" />
           </div>
         </div-->

      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <div class="total-info-wrap">
          <!-- <button type="button" class="btn-layout btn-grey mr-5">테스트 메세지 추가</button> -->
          <button type="button" class="btn-layout btn-grey" @click="regModalToggle()">{{ $t('button.add') }}</button>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="dataLength !== 0">
              <tr v-for="(item, idx) in tableData" :key="item.ip">
                <td class="roboto">{{ idx + 1 }}</td>
                <td>{{ item.adminId }}</td>
                <td>
                  <div class="toggle-wrap">
                    <label class="toggle-text default on"></label>
                    <input type="checkbox" class="set-switch" v-model="item.loginYn" @change="loginStatusSave(item)" />
                  </div>
                </td>
                <td>{{ item.statusTxt }}</td>
                <td style="width: 7%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="adminLogout(item.adminId)">{{ $t('table.head.out') }}</button>
                  </div>
                </td>
                <td>{{ item.adminGroup }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="adminDetail(item)">{{ $t('table.head.detailinfo') }}</button>
                    <!-- <button class="btn-innerTable btn-status-change wait" type="button">활동기록</button>  -->
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="adminDel(item)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="7">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
  <div class="modal-wrapper" v-if="regModalActive">
    <div class="modal-wrap">
      <article>
        <div class="sub-title">
          <h3 v-if="modalType === 'save'">{{ $t('common.addM') }}</h3>
          <h3 v-if="modalType === 'detail'">{{ $t('common.detailM') }}</h3>
        </div>
        <div class="page-content">
          <div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.id') }}</div>
              <div class="item-content">
                <input type="text" ref="adminId" v-model="saveData.adminId" :disabled="modalType === 'detail'" />
              </div>
            </div>
          </div>
          <div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.password') }}</div>
              <div class="item-content">
                <input type="password" ref="adminPass" v-model="saveData.adminPass" />
              </div>
            </div>
          </div>
          <div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('company.pwCheck') }}</div>
              <div class="item-content">
                <input type="password" ref="adminPassChk" v-model="saveData.adminPassChk" />
              </div>
            </div>
          </div>
          <!--div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.permission') }}</div>
              <div class="item-content">
                <select v-model="saveData.adminGroup">
                  <option>submaster</option>
                </select>
              </div>
            </div>
          </div>
          <div class="item-row-container" v-if="modalType === 'detail'">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.statusPT') }}</div>
              <div class="item-content">
                <input type="text" v-model="saveData.statusTxt" disabled />
              </div>
            </div>
          </div-->
          <div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.loginYn') }}</div>
              <div class="item-content">
                <select class="w70" v-model="saveData.loginYn">
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-btns">
            <a @click="adminSave">{{ $t('button.save') }}</a>
            <a @click="regModalToggle">{{ $t('button.close') }}</a>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { getCode } from '@/api/common'
import { adminList, adminSave } from '@/api/admin'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Memo from '@/components/common/memo'
export default {
  name: 'AdminList',
  components: {
    TableHead,
    Pagination,
    Memo
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'id', 'loginYn', 'status', 'block', 'auth', 'option']
      },
      reqData: {
        page: 1,
        tatal_list_count: 30,
        siteId: '',
        adminId: '',
        adminGroup: '',
        status: '1',
        delYn: 'N',
        useYn: 'Y'
      },
      saveData: {
        siteId: '',
        adminId: '',
        adminPass: '',
        adminGroup: '',
        adminOtp: '',
        loginYn: '',
        status: '',
        useYn: '',
        delYn: '',
        regDate: '',
        regId: '',
        adminName: '',
        ip: '',
        memo: '',
        lastLoginAdminId: '',
        lastLoginDate: ''
      },
      siteInfo: {},
      pageInfo: {},
      totalInfo: {},
      regModalActive: false,
      tableData: [],
      modalType: 'save',
      dataLength: 0
    }
  },
  methods: {
    searchReset () {
      this.searchOption.keyword = ''
      this.searchOption.text = ''
    },
    pageSeach () {
      const searchOptions = this.searchOption

      if (searchOptions.keyword) {
        this.reqData[searchOptions.keyword] = searchOptions.text
        this.setTableData(1)
      } else {
        alert('검색기준을 선택해주세요.')
      }
    },
    regModalToggle () {
      this.regModalActive = !this.regModalActive
      if (!this.regModalActive) {
        this.modalType = 'save'
      }
    },
    async adminDetail (item) {
      this.saveData = item
      this.modalType = 'detail'
      this.regModalToggle()
    },
    async adminCheck (adminId) {
      const req = {
        adminId
      }
      const res = await adminList(req)
      console.log(res)
      const exist = res.data.list.length
      if (exist === 0) {
        return false
      } else {
        return true
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async adminSave () {
      const saveData = lodash.cloneDeep(this.saveData)

      if (this.modalType === 'save') {
        if (!saveData.adminId) {
          this.$refs.adminId.focus()
          alert('관리자 아이디를 입력해주세요.')
          return
        }
      }
      if (!saveData.adminPass) {
        this.$refs.adminPass.focus()
        alert('비밀번호를 입력해주세요.')
        return
      }
      if (!saveData.adminPassChk) {
        this.$refs.adminPassChk.focus()
        alert('확인용 비밀번호를 입력해주세요.')
        return
      }
      /* if (!saveData.adminGroup) {
        alert('관리자 그룹을 선택해주세요.')
        return
      } */
      if (!saveData.loginYn) {
        alert('로그인 허용을 선택해주세요.')
        return
      }
      if (saveData.adminPass !== saveData.adminPassChk) {
        alert('비밀번호가 다릅니다. 다시 입력해주세요.')
        return
      } else {
        delete saveData.adminPassChk
      }

      if (this.modalType === 'save') {
        const idOverlap = await this.adminCheck(saveData.adminId)
        if (idOverlap) {
          this.saveData.adminId = ''
          alert('존재하는 아이디입니다. 다시 입력해주세요.')
          return
        }
      }

      if (this.modalType === 'detail') {
        console.log('detail save')
        for (const key in saveData) {
          if (typeof saveData[key] === 'boolean') {
            console.log(typeof saveData[key])
            if (saveData[key]) {
              saveData[key] = 'Y'
            } else {
              saveData[key] = 'N'
            }
          }
        }
      }

      saveData.siteId = this.siteInfo.siteId
      let saveTxt = '등록'
      if (this.modalType === 'detail') {
        saveTxt = '수정'
      }
      console.log(saveData)
      const res = await adminSave(saveData)
      if (res.resultCode === '0') {
        alert(`관리자 ${saveTxt} 완료`)
        for (const key in this.saveData) {
          this.saveData[key] = ''
        }
        this.regModalToggle()
        this.setTableData(1)
      } else {
        alert(`관리자 ${saveTxt} 실패, 다시 시도해주세요.`)
      }
    },
    async loginStatusSave (item) {
      const adminInfo = lodash.cloneDeep(item)
      const req = {
        siteId: this.siteInfo.siteId,
        adminId: adminInfo.adminId,
        loginYn: !adminInfo.loginYn
      }
      if (req.loginYn) {
        req.loginYn = 'Y'
      } else {
        req.loginYn = 'N'
      }
      const res = await adminSave(req)
      if (res.resultCode === '0') {
        if (!confirm('로그인 변경 하시겠습니까?')) {
          alert('취소를 누르셨습니다.')
        } else {
          alert('로그인 변경 완료')
          this.setTableData(1)
        }
      } else {
        alert('로그인 변경 실패, 다시 시도해주세요.')
      }
    },
    async adminDel (item) {
      const adminInfo = lodash.cloneDeep(item)
      const req = lodash.cloneDeep(this.saveData)
      req.siteId = this.siteInfo.siteId
      req.adminId = adminInfo.adminId
      req.delYn = 'Y'
      const res = await adminSave(req)
      if (res.resultCode === '0') {
        if (!confirm('삭제 하시겠습니까?')) {
          alert('취소를 누르셨습니다.')
        } else {
          alert('삭제 완료')
          this.setTableData(1)
        }
      } else {
        alert('삭제 실패, 다시 시도해주세요.')
      }
    },
    async getAdminList (page) {
      if (page) {
        this.reqData.page = page
      }
      const req = lodash.cloneDeep(this.reqData)
      const res = await adminList(req)
      return res.data
    },
    async setTableData (page) {
      const data = await this.getAdminList(page)
      const list = data.list
      for (const item of list) {
        switch (item.status) {
          case '1':
            item.statusTxt = '활성'
            break
          case '-2':
            item.statusTxt = '잠김'
            break
        }
        if (item.loginYn === 'Y') {
          item.loginYn = true
        } else {
          item.loginYn = false
        }
        if (item.delYn === 'Y') {
          item.delYn = true
        } else {
          item.delYn = false
        }
      }
      this.tableData = list
      this.dataLength = this.tableData.length
      if (this.tableData.length !== 0) {
        this.pageInfo = data.pageInfo
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
      }
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.setTableData(1)
  }
}
</script>

<style scoped>
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.toggle-wrap {
  width: auto;
  justify-content: center;
}
.modal-wrapper {
  display: flex;
}
.item-wrap > div.item-title {
  min-width: 100px;
}
.item-content > select {
  width: 183px;
}
input:read-only {
  user-select: none;
}
</style>
